











import Vue from 'vue';
import Component from 'vue-class-component';
import VueSecureHtml from 'vue-html-secure';
import { BContainer } from 'bootstrap-vue';
import { Prop } from 'vue-property-decorator';
import InformationComponent from '@/components/InformationComponent.vue';
import InformationPageType from '@/utils/enums/InformationPageType';

Vue.use(VueSecureHtml);

@Component({
  components: {
    InformationComponent,
    BContainer,
  },
})
export default class InformationPage extends Vue {
  @Prop({ required: true })
  pageType!: InformationPageType;
}
